import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { MappedImage } from '../Image'
import parse from 'html-react-parser'
import {
  mapSubModuleFields,
  textStyle,
  backgroundStyle,
  buttonStyle,
} from '../../utils'
import { GTM } from '../../constants'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { useSearchParams } from '../../libs/hooks'

export const MultiColumn = ({
  heading,
  columnNum,
  subModules,
  buttonLink,
  buttonText,
  payerLink,
  orgId,
  style,
}) => {
  const findButtonLink = subModules.find(item => item.buttonLink)
  const btnLinkQuery = findButtonLink ? findButtonLink.buttonLink : buttonLink
  const url = useSearchParams(btnLinkQuery, orgId, payerLink)
  // extract container type
  const container = subModules.map(item => item.containerType)
  // get boolean for conditional styles to flip column text and image
  const flip = container[0] || container[1] === 'FlippedCol Item' ? true : false

  return (
    <Container
      className="multi-column-outer-container"
      fluid
      css={backgroundStyle(style?.backgroundColor)}
    >
      <div className="mw multi-column-inner-container">
        {flip && <hr className="lg-divider"></hr>}
        {heading && (
          <Row
            className="w-100 p-sm-2 p-md-4 text-center"
            css={textStyle(style?.headingColor)}
          >
            <h2 className="module-heading">{heading}</h2>
          </Row>
        )}
        <Row
          md={columnNum}
          lg={columnNum}
          className={`w-100 p-sm-2 ${
            !flip && 'p-md-4'
          } text-center col-wrapper`}
        >
          {subModules &&
            subModules.map((item, index) => {
              const alignmentStyle = css`
                text-align: ${item.textAlign};
              `
              return (
                <Col
                  key={item.heading}
                  sm={12}
                  className={`my-4 px-4 d-flex flex-column justify-content-start multi-column-submodule-wrapper-${index}`}
                >
                  <div
                    className={`column-item-inner-${index} p-2 ${
                      flip && 'column-flip'
                    }`}
                    css={backgroundStyle(item?.style?.backgroundColor)}
                  >
                    {item.image && (
                      <div className="img-one-wrapper">
                        <MappedImage image={item.image} />
                      </div>
                    )}
                    {item.logo && (
                      <div className="img-two-wrapper">
                        <MappedImage image={item.logo} />
                      </div>
                    )}
                    <div className="col-text-wrapper" css={alignmentStyle}>
                      {item.heading && (
                        <h2
                          className="my-3 col-heading"
                          css={textStyle(item?.style?.headingColor)}
                        >
                          {item.heading}
                        </h2>
                      )}
                      {item.divider && <hr className="sm-divider" />}
                      {item.subtitle && (
                        <div
                          className="my-3 col-subheading"
                          css={textStyle(item?.style?.subHeadingColor)}
                        >
                          {parse(item.subtitle)}
                        </div>
                      )}
                      {item.richText && (
                        <div
                          className="my-3 col-rich-text"
                          css={textStyle(item?.style?.textColor)}
                        >
                          {item.richText}
                        </div>
                      )}
                      {item.bodyText && (
                        <div
                          className="col-body-text"
                          css={textStyle(item?.style?.textColor)}
                        >
                          {parse(item.bodyText.bodyText)}
                        </div>
                      )}
                      {item.htmlText && (
                        <div
                          className="col-html-text"
                          css={textStyle(item?.style?.textColor)}
                        >
                          {parse(item.htmlText.htmlText)}
                        </div>
                      )}
                      {item.button && (
                        <div className="card-btn-wrapper">
                          <a
                            href={url}
                            aria-label={`${item.button}`}
                            id={orgId + '-' + GTM.FIND_OUT_IF_YOU_QUALIFY}
                            css={
                              item.buttonType !== 'Decorated text' &&
                              buttonStyle(
                                style?.buttonColor,
                                style?.buttonTextColor
                              )
                            }
                            className={`${
                              item.buttonType === 'Decorated text'
                                ? 'decorated-text-btn'
                                : 'site-btn'
                            }`}
                          >
                            {item.button}{' '}
                            {item.buttonType === 'Decorated text' && (
                              <FontAwesomeIcon icon={faCaretRight} />
                            )}
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="item-list-wrapper">
                      {item.imageList &&
                        item.imageList.map((img, index) => (
                          <div
                            key={index}
                            className={`img-${index}`}
                            alt={img.title}
                          >
                            <MappedImage image={img} />
                          </div>
                        ))}
                      {item.textList &&
                        item.textList.map((textItem, index) => (
                          <div key={index} className="text-list-wrapper">
                            <p className={`text-item-${index}`}>{textItem}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </Col>
              )
            })}
        </Row>
      </div>
      {buttonLink ? (
        <Row className="pb-md-4 text-center col-wrapper">
          <Col>
            <div>
              <a href={url} className="site-btn">
                {buttonText}
              </a>
            </div>
          </Col>
        </Row>
      ) : null}
    </Container>
  )
}

const mapTwoColumnModuleProps = props => {
  return { ...mapSubModuleFields(props), columnNum: 2 }
}

const mapThreeColumnModuleProps = props => {
  return { ...mapSubModuleFields(props), columnNum: 3 }
}

const mapFourColumnModuleProps = props => {
  return { ...mapSubModuleFields(props), columnNum: 4 }
}

export const mapMultiColumnModule = props => {
  if (props.moduleType) {
    switch (props.moduleType) {
      case 'Two Column':
        return mapTwoColumnModuleProps(props)
      case 'Three Column':
        return mapThreeColumnModuleProps(props)
      case 'Four Column':
        return mapFourColumnModuleProps(props)
      default:
        return
    }
  }
}
